<template>
  <footer class="container-fluid main-footer">
    <p>© 2015-{{ currentYear }} Università di Pisa - <router-link :to="{ name: 'credits' }">Credits</router-link></p>
    <p><router-link :to="{ name: 'glossary' }">Glossario</router-link> - <router-link :to="{ name: 'laws' }">Riferimento normativo</router-link></p>
  </footer>
</template>

<script>

export default {
  data () {
    return {
      currentYear: (new Date()).getFullYear(),
    };
  },
};

</script>

<style lang="scss">
</style>
